<template>
  <div class="about">
    <div class="grey lighten-4">
      <v-container class="container-max-width py-0 pt-3 grey--text text--darken-2">
        <div class="grey lighten-4 py-1">
          <v-row no-gutters class="flex-nowrap" align="start">
          <span>
            <v-icon
              size="56px"
              color="primary"
              class="pr-4">
              mdi-credit-card
            </v-icon>
          </span>
            <div>
              <h3 class="text-uppercase">Персональні дані</h3>
              <p class="mb-1">Умови обробки персональних даних</p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>
    <v-container class="container-max-width">
      <v-card min-height="450px" class="py-4 px-5 grey--text text--darken-2 text-md-justify">
        <h3>Умови та правила обробки персональної інформації</h3>
        <p class="pt-1">
          Надсилаючи звернення особа, що звертається зі зверненням (далі – Користувач) через веб-сайт http://pay24.net.ua (далі – Сайт) надає право на обробку його персональних даних, згідно з чинним законодавством України, та з метою зазначеною нище.
         </p>
        <p>
          Згода на обробку персональних даних надається з моменту надіслання Користувачем звернення через Сайт шляхом проставляння відмітки під текстом «Даю згоду на обробку персональних даних».
          Зокрема, Користувач дає свою згоду на обробку його персональних даних, у тому числі – на будь-яку дію чи сукупність дій, таких як збір, реєстрація, накопичення, зберігання, адаптування, зміна, відновлення, використання й розповсюдження (розповсюдження, реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних (автоматизованих) систем.
        </p>
        <h3>Користувач також дає згоду на інші дії, які може здійснювати Компанією з його персональними даними в письмовій (паперовій), електронній та іншій формі, включаючи, та не обмежуючись такими персональними даними:</h3>
        <ul>
          <li>ім'я Користувача</li>
          <li>адреса електронної пошти</li>
          <li>Прізвище, ім’я та по батькові</li>
          <li>ip-адреса, інші комунікаційні дані Користувачів</li>
          <li>повідомлення, листи, заяви, що передаються Користувачем</li>
        </ul>
        <p class="pt-1">Користувач також надає згоду на надсилання інформації, повідомлень на надані ним адреси електронної пошти, номери телефонів.</p>
          <h3>Персональні дані Користувача обробляються Компанією з метою:</h3>
          <ul>
            <li>розгляду звернення Користувача</li>
            <li>збору статистичної інформації з роботи Сайту для її обробки та використання при впровадженні нових сервісів та послуг, нововведень та покращень</li>
            <li>для інформування Користувача про умови надання послуг, зміни в сервісах та організації Сайту, а також для інших цілей, що не суперечать чинному законодавству України</li>
          </ul>
        <p>Персональні дані Користувача, що обробляються Компанією згідно з цим документом, зберігаються протягом всього терміну діяльності Компанії.</p>
          <h3>Права Користувача - суб'єкта персональних даних:</h3>
          <ul>
            <li>знати про місцезнаходження бази персональних даних, що містить персональні дані Користувача, її призначення та найменування</li>
            <li>місцезнаходження власника та розпорядників персональних даних або дати відповідне доручення про отримання цієї інформації уповноваженому представнику, крім випадків, встановлених законом</li>
            <li>отримувати інформацію про умови надання доступу до персональних даних, включаючи інформацію про третіх осіб, яким передаються персональні дані Користувача</li>
            <li>мати доступ до своїх персональних даних</li>
            <li>отримувати не пізніше ніж за тридцять календарних днів із дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи зберігаються персональні дані Користувача у базі персональних даних, а також отримувати зміст персональних даних, що зберігаються</li>
            <li>пред’являти мотивовану вимогу власникові персональних даних із запереченням проти обробки персональних даних</li>
            <li>пред’являти мотивовану вимогу про зміну або знищення персональних даних власником та/або розпорядником персональних даних, якщо ці дані обробляються незаконно або є недостовірними</li>
            <li>на захист персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з навмисним прихованням, ненаданням або несвоєчасним наданням, а також на захист від надання даних, що є недостовірними або такими, що ганьблять честь, гідність і ділову репутацію</li>
            <li>звертатися зі скаргами на обробку персональних даних в органи державної влади та до посадовців, у повноваження яких входить забезпечення захисту персональних даних, або до суду</li>
            <li>застосовувати засоби правового захисту у випадку порушення законодавства про захист персональних даних</li>
            <li>відкликати згоду на обробку персональних даних</li>
            <li>знати механізм автоматичної обробки персональних даних</li>
          </ul>
        <p class="pt-1">
          Персональні дані, крім знеособлених персональних даних, за режимом доступу є інформацією з обмеженим доступом.
          Компанія розкриває персональні та інші дані Користувача виключно з метою, що пов’язана з розглядом його звернень.
          У відповідності з вимогами чинного законодавства України Компанія може розкривати частину даних Користувача третім особам з метою, пов’язаною з їхніми законними правами й повноваженнями, у тому числі державним органам та закладам, органам місцевого самоврядування, комунальним підприємствам при дотриманні встановленого порядку надання таких даних.
          Використання персональних даних з історичною, статистичною або науковою метою здійснюється виключно у знеособленому вигляді. Використання персональних даних Користувача у знеособленому вигляді не вимагає його додаткової згоди.
          Компанія забезпечує безпеку даних Користувача від втрати, розголосу та несанкціонованого доступу третіх осіб із застосуванням технологій систем безпеки, впровадження режиму обмеженого доступу й контролю доступу до даних авторизованими співробітниками.
          Користувач також надає згоду на надсилання інформації, повідомлень на надані ним адреси електронної пошти, номери телефонів, тощо.
        </p>
      </v-card>
    </v-container>
  </div>
</template>

<script>
    export default {
        name: "PersonalData"
    }
</script>

<style scoped>

</style>
